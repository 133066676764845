import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import { Text } from "../components/contentCard"
import Layout from "../components/layout"
import { min } from "../utils/theme"

const Meista = () => {
  const images = useStaticQuery(graphql`
    query {
      janne: file(relativePath: { eq: "janne2.jpg" }) {
        childImageSharp {
          fixed(height: 300, width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pirjo: file(relativePath: { eq: "pirjo1.jpg" }) {
        childImageSharp {
          fixed(height: 250, width: 250) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      em: file(relativePath: { eq: "em2.jpg" }) {
        childImageSharp {
          fixed(height: 250, width: 250) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Meistä" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <CenteredWrapper>
          <TitleHeading>
            Credos Oy:llä on erittäin laaja kokemus yritysjohtamisesta ja
            hallitustyöskentelystä.
            <br />
            Avustamme asiakasyrityksiämme yrityksen strategisessa suunnittelussa
            sekä tehtyjen suunnitelmien läpiviennissä.
            <br />
            Olemme mielellämme vastuussa tekemiemme suunnitelmien
            toteuttamisessa osallistumalla yrityksen hallitustyöskentelyyn.
          </TitleHeading>
        </CenteredWrapper>
        <ProfileWrapper>
          <Img fixed={images.janne.childImageSharp.fixed} alt="" />
          <BottomText>
            <Text>
              Credos Oy:n hallituksen puheenjohtajalla ja toimitusjohtajalla
              <Name> Janne Kalliomäellä</Name> on yli 30 vuoden kokemus
              työskentelystä eri yritysten johtotehtävissä, joista yli 10 vuotta
              yrityksen toimitusjohtajana. Lisäksi hän on ollut puheenjohtajana
              tai jäsenenä yli 20 eri yrityksen hallituksessa. Janne on
              koulutukseltaan diplomi-insinööri ja hän on opinnoissaan
              keskittynyt operaatiotutkimukseen sekä yritystoiminnan
              suunnitteluun.
              <br /> Janne on suorittanut jatko-opintoja markkinoinnissa sekä
              toiminut myynnin ja markkinoinnin johtotehtävissä sekä B2B- ja
              B2C-markkinoinnissa.
            </Text>
          </BottomText>
        </ProfileWrapper>
        <ProfileWrapperSecondary>
          <ProfileWrapper>
            <Img fixed={images.pirjo.childImageSharp.fixed} alt="" />
            <BottomText>
              <Text>
                <Name>Pirjo Ketola</Name> on kokenut projektipäällikkö ja hän on
                johtanut useita suuria ja vaativia asiakasprojekteja. Pirjon
                vahvuuksia ovat mm. asiakastarpeiden tunnistaminen,
                monitahoisten projektien organisointi ja pilkkominen
                osatehtäviin. <br /> Pirjo työskentelee kansainvälisessä
                IT-yrityksessä työvoimahallinnan asiantuntijatehtävissä.
              </Text>
            </BottomText>
          </ProfileWrapper>
          <ProfileWrapper>
            <Img fixed={images.em.childImageSharp.fixed} />
            <BottomText>
              <Text>
                <Name>Emma Pohjalainen</Name> on digitaalisen liiketoiminnan
                asiantuntija, jonka rooli hallituksessa on asiakkaiden ongelmien
                digitaalisten ratkaisujen ideointi sekä sosiaalisen median
                hyödyntäminen liiketoiminnassa. <br /> Emma työskentelee
                konsulttina Lontoossa ja tuo kansainvälistä näkökulmaa Credos
                Oy:n työskentelyyn.
              </Text>
            </BottomText>
          </ProfileWrapper>
        </ProfileWrapperSecondary>
      </Container>
    </Layout>
  )
}

export default Meista

const Container = styled.section`
  max-width: 1250px;
  margin: 0 auto;
`
const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3rem auto;
  max-width: 1000px;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;
  text-align: center;
`
const TitleHeading = styled.h2`
  font-weight: normal;
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  ${min("small")(css`
    flex-direction: row;
  `)};

  ${min("large")(css`
    padding: 0;
  `)};
`

const Name = styled.strong`
  font-family: "Merriweather", serif;
`

const ProfileWrapperSecondary = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;

  & > ${ProfileWrapper} {
    flex: 1 1 100%;

    ${min("medium")(css`
      flex: 0 0 50%;
    `)};

    &:nth-child(2) {
      ${min("medium")(css`
        flex-direction: row-reverse;
        border-left: 1px solid black;
      `)};
    }
  }

  ${min("large")(css`
    flex-direction: row;
  `)};
`

const BottomText = styled.div`
  flex-basis: 70%;
  padding: 1rem 1rem 2rem;

  ${min("large")(css`
    padding: 1rem;
  `)};
`
